<script>
import { defineComponent } from 'vue'

import kosmosLogo from "@/components/UI-kit/logos/kosmos-logo.vue";
import telegramLogo from "@/components/UI-kit/logos/telegram-logo.vue";
import whatsappLogo from "@/components/UI-kit/logos/whatsapp-logo.vue";

import { openWhatsapp } from "@/components/functions/openWhatsapp/openWhatsapp";

export default defineComponent({
  name: "kosmos-header",

  components: {
    kosmosLogo,
    telegramLogo,
    whatsappLogo,
  },

  data() {
    return {
      openWhatsapp,
    }
  },
})
</script>

<template>
  <div class="kosmos-header">
    <div class="logo-wrapper">
      <kosmos-logo type="dark" />

      <h1 class="title">Dr.KosMos</h1>
    </div>

    <main>
      <nav class="only-ds">
        <a href="#about"><h2>О нас</h2></a>
        <a href="#courses"><h2>Курсы</h2></a>
        <a href="#team"><h2>Команда</h2></a>
<!--        <a href="#feedback"><h2>Отзывы</h2></a>-->
        <a href="#contacts"><h2>Контакты</h2></a>
      </nav>

      <div class="contacts">
        <a href="https://t.me/Dr_KosMedMos" target="_blank"><telegram-logo /></a>

        <whatsapp-logo @click="openWhatsapp" />
      </div>
    </main>

    <div class="burger only-mb" @click="$emit('openBurgerMenu')">
      <div class="line" />
      <div class="line" />
      <div class="line" />
    </div>
  </div>
</template>

<style scoped lang="sass">
.kosmos-header
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%

  @media (min-width: $desktopScreenMinWidth)
    max-width: 1440px
    padding: 28px 115px 27px 112px

  @media (max-width: $mobileScreenMaxWidth)
    padding: 30px 20px 21px

  .logo-wrapper
    display: flex
    flex-direction: column
    align-items: center

    @media (min-width: $desktopScreenMinWidth)
      gap: 10px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 7px

    &:hover
      cursor: pointer

    > .title
      font-weight: 500
      line-height: 120%
      color: $violet

      @media (min-width: $desktopScreenMinWidth)
        font-size: 20px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 14px

  main
    display: flex
    gap: 123px
    align-items: center

    nav
      display: flex
      gap: 30px
      align-items: center

      > *
        font-weight: 700
        font-size: 20px
        line-height: 120%
        color: $violet

    .contacts
      display: flex
      gap: 20px

      > *
        &:hover
          cursor: pointer

  .burger
    display: flex
    flex-direction: column
    gap: 8px
    width: 38px

    &:hover
      cursor: pointer

    > *
      width: 100%
      height: 2px
      +border-radius(999px)
      background-color: $blackViolet
</style>