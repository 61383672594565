<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "whatsapp-logo",
})
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" @click="$emit('click')">
    <g clip-path="url(#clip0_112_825)">
      <path d="M20 0C8.95601 0 0 8.95601 0 20C0 31.044 8.95601 40 20 40C31.044 40 40 31.044 40 20C40 8.95601 31.044 0 20 0ZM20.4239 31.6445C20.4236 31.6445 20.4242 31.6445 20.4239 31.6445H20.419C18.4155 31.6437 16.4469 31.1414 14.6985 30.188L8.35297 31.8521L10.0513 25.6509C9.00359 23.8363 8.45242 21.7777 8.45336 19.6686C8.45609 13.0707 13.826 7.70297 20.4239 7.70297C23.6258 7.70414 26.6315 8.95047 28.8913 11.2122C31.1515 13.4741 32.3953 16.4807 32.3941 19.678C32.3913 26.2762 27.0209 31.6446 20.4238 31.6446L20.4239 31.6445Z" fill="#59239D"/>
      <path d="M20.4283 9.72461C14.9402 9.72461 10.4771 14.1859 10.4746 19.6699C10.4741 21.5492 11.0002 23.3794 11.9959 24.9629L12.2324 25.3392L11.2272 29.0095L14.9928 28.022L15.3562 28.2375C16.8836 29.1438 18.6348 29.6233 20.42 29.6239H20.4239C25.9077 29.6239 30.3709 25.1622 30.3733 19.6779C30.3742 17.0201 29.3403 14.5213 27.462 12.6415C25.5836 10.7616 23.0854 9.72547 20.4283 9.72461ZM26.2803 23.9461C26.0309 24.6443 24.8362 25.2819 24.2616 25.368C23.7461 25.4448 23.0943 25.4769 22.3777 25.2495C21.9431 25.1116 21.3862 24.9276 20.6724 24.6196C17.6722 23.3245 15.7127 20.3045 15.5631 20.1048C15.4136 19.9052 14.3418 18.4834 14.3418 17.0116C14.3418 15.54 15.1145 14.8165 15.3886 14.5173C15.6629 14.218 15.987 14.1432 16.1863 14.1432C16.3855 14.1432 16.5852 14.1451 16.7595 14.1536C16.9431 14.1627 17.1897 14.0837 17.4323 14.6669C17.6816 15.2657 18.2798 16.7372 18.3546 16.8868C18.4294 17.0366 18.4791 17.2112 18.3796 17.4108C18.2798 17.6103 17.9484 18.0409 17.632 18.4334C17.4991 18.5979 17.3262 18.7444 17.5007 19.0437C17.6749 19.3428 18.2755 20.3221 19.1645 21.115C20.3071 22.1337 21.2708 22.4492 21.5699 22.5991C21.8687 22.7486 22.0432 22.7235 22.2178 22.5243C22.392 22.3247 22.9655 21.6512 23.1647 21.3518C23.3641 21.0524 23.5636 21.1024 23.8377 21.2023C24.112 21.3017 25.5823 22.0253 25.8814 22.1748C26.1805 22.3247 26.3798 22.3995 26.4545 22.5243C26.5296 22.6491 26.5296 23.2476 26.2803 23.9461Z" fill="#59239D"/>
    </g>
    <defs>
      <clipPath id="clip0_112_825">
        <rect width="40" height="40" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="sass">

</style>